import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";

import { Redirect } from "react-router-dom";
import { LoadingPage } from "../../../component/loading/loading-page";
import { OAuth2Service } from "../../../services/authentication/authentication/oauth2-service";

class LoginPage extends React.Component<WithTranslation, { error: Error | null }> {
    public constructor(props: WithTranslation) {
        super(props);
        this.state = {
            error: null,
        }
        OAuth2Service.instance.signinRedirect().catch((error: any) => {
            this.setState({ error: error });
        });
    }

    public render(): ReactNode {
        if (this.state.error) {
            return (
                <Redirect to="/error"></Redirect>
            )
        }
        return <LoadingPage description={this.props.t("loginRedirect")}></LoadingPage>;
    }

}

const LoginPageTranslated = withTranslation("loading")(LoginPage)
export { LoginPageTranslated as LoginPage }