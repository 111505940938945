import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import React from "react";
import { EuiDatePicker, EuiFlexGroup, EuiFlexItem, EuiForm, EuiFormRow, EuiPanel, EuiSelect, EuiStat, EuiText, EuiTitle } from "@elastic/eui";
import { DashboardPage } from "../../component/dashboard-page/dashboard-page";
import { IntroductionModal } from "../../component/introduction-modal/introduction-modal";
import moment from "moment";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { connect } from "react-redux";
import { HomeStateProps, getOverview, homeSlice } from "../../redux/home/home.reducer";
import { bindActionCreators } from "@reduxjs/toolkit";
import { GenderEnum } from "../../models/account/gender-enum";
import { UserState } from "redux-oidc";
import { ReceiptsStateProps } from "../../redux/wallet/receipts/receipts.reducer";
import { RecentReceipts } from "../../component/wallet/recent-receipts/recent-receipts";
import { FormatCurrency } from "../../utils/currency-format";
import { AccountStateProps, accountSlice, saveProfile } from "../../redux/account/account.reducer";
import { notification } from "antd";
class HomePage extends React.Component<WithTranslation & { oidc: UserState, receipts: ReceiptsStateProps } & RouteComponentProps & { home: HomeStateProps, account: AccountStateProps } & Props & { dispatch: any }> {
    public componentDidMount() {
        this.props.getOverview({});
    }
    public render() {
        const { user } = this.props.oidc;
        const { overview } = this.props.home;

        return (
            <DashboardPage title={this.props.t("welcome", { name: user?.profile.fullname.split(" ")[0] })}>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiPanel>
                                    <EuiStat isLoading={overview.loading} title={FormatCurrency(overview.data?.todaySpendings)} description={this.props.t("overview.today-expenses")}>
                                    </EuiStat>
                                </EuiPanel>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiPanel>
                                    <EuiStat isLoading={overview.loading} title={FormatCurrency(overview.data?.lastWeekSpendings)} description={this.props.t("overview.week-expenses")}>
                                    </EuiStat>
                                </EuiPanel>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiPanel>
                                    <EuiStat isLoading={overview.loading} title={FormatCurrency(overview.data?.yearSpendings)} description={this.props.t("overview.year-expenses")}>
                                    </EuiStat>
                                </EuiPanel>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiFlexItem>
                </EuiFlexGroup>
                <EuiFlexGroup>
                    <EuiFlexItem>
                        <RecentReceipts></RecentReceipts>
                    </EuiFlexItem>
                </EuiFlexGroup>
            </DashboardPage >
        );
    }
}

const mapStateToProps = (state: SmartbillsRootState): { home: HomeStateProps, oidc: UserState, account: AccountStateProps } => {
    const { home, account, oidc, wallet } = state;
    return {
        home: home,
        account: account,
        oidc: oidc,
    }
};
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...homeSlice.actions,
        saveProfile,
        getOverview,
        ...accountSlice.actions,
    }, dispatch);
};

type Props = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;


const HomePageTranslated = withTranslation("home")(connect(mapStateToProps, mapDispatchToProps)(withRouter(HomePage)));
export { HomePageTranslated as HomePage }