import { reducer as oidcReducer } from 'redux-oidc';
import { combineReducers } from "redux";
import { DashboardLayoutReducer } from './dashboard-layout/dashboard-layout.reducer';
import { IntroductionModalReducer } from './introduction-modal/introduction-modal.reducer';
import { AccountReducer } from './account/account.reducer';
import { homeSlice } from './home/home.reducer';
import { ReceiptsReducer } from './wallet/receipts/receipts.reducer';
import { CompaniesReducer } from './explore/companies/companies.reducer';
import { connectRouter } from 'connected-react-router';
import { History } from "history";
import { StateType } from "typesafe-actions";

export const smartbillsReducer = (history: History) => combineReducers({
    router: connectRouter(history),
    introductionModal: IntroductionModalReducer,
    account: AccountReducer,
    wallet: combineReducers({
        receipts: ReceiptsReducer
    }),
    explore: combineReducers({
        companies: CompaniesReducer
    }),
    oidc: oidcReducer,
    layout: DashboardLayoutReducer,
    home: homeSlice.reducer
})

export type SmartbillsRootState = StateType<ReturnType<typeof smartbillsReducer>>
