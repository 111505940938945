import { NavigationHeader } from "../header/header";
import React from "react";
import { Redirect } from "react-router-dom";
import { Router } from "../router/router";
import { SideMenu } from "../side-menu/side-menu";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { UserState } from 'redux-oidc';
import { connect } from "react-redux";
import { EuiCallOut, EuiDatePicker, EuiEmptyPrompt, EuiForm, EuiFormRow, EuiPage, EuiSelect, EuiText } from "@elastic/eui";
import { WithTranslation, withTranslation } from "react-i18next";
import { SmartbillsAPI } from "../../services/authentication/smartbills-api";
import { AccountStateProps, updateAccount, saveProfile, accountSlice } from "../../redux/account/account.reducer";
import { IntroductionModal } from "../../component/introduction-modal/introduction-modal";
import moment from "moment";
import { GenderEnum } from "../../models/account/gender-enum";
import { bindActionCreators } from "@reduxjs/toolkit";
import { LoadingPage } from "../../component/loading/loading-page";

class DashboardLayout extends React.Component<WithTranslation & { oidc: UserState, account: AccountStateProps } & DashboardLayoutProps> {
    public constructor(props: any) {
        super(props);
        SmartbillsAPI.setToken(this.props.oidc.user?.access_token);
    }

    public componentDidMount() {
        this.props.updateAccount({});
    }

    public render() {
        if (this.props.account.updateProfile.loading) {
            return <LoadingPage description={this.props.t("loading:loading")}></LoadingPage>
        } else {
            return (
                <EuiPage >
                    <NavigationHeader></NavigationHeader>
                    <SideMenu></SideMenu>
                    <Router></Router>
                    {!this.props.account.profile.completed &&
                        <IntroductionModal
                            title={this.props.t("intro-modal.title")} finishProps={{
                                onClick: () => this.props.saveProfile(), text: this.props.t("intro-modal.save"), loading: this.props.account.completeProfile.loading
                            }}
                            steps={[
                                {
                                    body: (
                                        <EuiForm>
                                            <EuiFormRow fullWidth>
                                                <EuiText dangerouslySetInnerHTML={{ __html: this.props.t("intro-modal.welcome-step.title", { name: "Sébastien" }) }}></EuiText>
                                            </EuiFormRow>
                                            <EuiFormRow fullWidth>
                                                <EuiText >{this.props.t("intro-modal.welcome-step.description")}</EuiText>
                                            </EuiFormRow>
                                        </EuiForm>
                                    ),
                                    nextButtonProps: {
                                        text: this.props.t("intro-modal.next"),
                                    },
                                    previousButtonProps: {
                                        text: this.props.t("intro-modal.previous"),
                                    }
                                },
                                {
                                    body:
                                        <EuiForm>
                                            <EuiFormRow fullWidth>
                                                <EuiText>{this.props.t("intro-modal.gender-step.question")}</EuiText>
                                            </EuiFormRow>
                                            <EuiFormRow fullWidth >
                                                <EuiSelect hasNoInitialSelection value={this.props.account.profile.gender} onChange={(event) => {
                                                    this.props.genderChange(Number.parseInt(event.target.value))
                                                }} fullWidth
                                                    options={[
                                                        { text: this.props.t("intro-modal.gender-step.male"), value: GenderEnum.MALE },
                                                        { text: this.props.t("intro-modal.gender-step.female"), value: GenderEnum.FEMALE },
                                                        { text: this.props.t("intro-modal.gender-step.non-binary"), value: GenderEnum.NON_BINARY }]}>
                                                </EuiSelect>
                                            </EuiFormRow>
                                        </EuiForm>,
                                    nextButtonProps: {
                                        text: this.props.t("intro-modal.next"),
                                    },
                                    previousButtonProps: {
                                        text: this.props.t("intro-modal.previous"),
                                    }
                                },
                                {
                                    body:
                                        <EuiForm>
                                            <EuiFormRow fullWidth>
                                                <EuiText >{this.props.t("intro-modal.birthday-step.question")}</EuiText>
                                            </EuiFormRow>
                                            <EuiFormRow fullWidth>
                                                <EuiDatePicker openToDate={moment().subtract(18, "y")} onClear={() => this.props.birthdayChange(null)} selected={this.props.account.profile.birthday} onChange={(date) => this.props.birthdayChange(date)} maxDate={moment().subtract(13, "y")}></EuiDatePicker>
                                            </EuiFormRow>
                                        </EuiForm>,
                                    nextButtonProps: {
                                        disabled: !this.props.account.profile.birthday,
                                        text: this.props.t("intro-modal.next"),
                                    },
                                    previousButtonProps: {
                                        text: this.props.t("intro-modal.previous"),
                                    }
                                },
                                {
                                    body:
                                        <EuiForm>
                                            {this.props.account.completeProfile.error &&
                                                <EuiFormRow fullWidth>
                                                    <EuiCallOut color="danger" title={`${this.props.t("error-notification")}: ${this.props.account.completeProfile.error?.message}`} ></EuiCallOut>
                                                </EuiFormRow>
                                            }
                                            <EuiFormRow fullWidth>
                                                <EuiText>{this.props.t("intro-modal.ready-step.title")}</EuiText>
                                            </EuiFormRow>
                                            <EuiFormRow fullWidth>
                                                <EuiText dangerouslySetInnerHTML={{ __html: this.props.t("intro-modal.ready-step.receipts", { email: this.props.oidc.user?.profile.email }) }}></EuiText>
                                            </EuiFormRow>
                                        </EuiForm>,
                                    previousButtonProps: {
                                        text: this.props.t("intro-modal.previous"),
                                    }
                                }
                            ]}></IntroductionModal >
                    }
                </EuiPage >
            );
        }
    }
}

const mapStateToProps = (state: SmartbillsRootState): { oidc: UserState, account: AccountStateProps } => {
    const { oidc } = state;
    const { account } = state;

    return {
        oidc: oidc,
        account: account
    };
};
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...accountSlice.actions,
        updateAccount,
        saveProfile,
    }, dispatch)
}

type DashboardLayoutProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & UserState;


const DashboardLayoutConnected = connect(mapStateToProps, mapDispatchToProps)(withTranslation(["home", "loading"])(DashboardLayout));
export { DashboardLayoutConnected as DashboardLayout }