import { createSlice } from "@reduxjs/toolkit";

export type IntroductionModalSliceState = { currentPage: number, data: any }
const initialState: IntroductionModalSliceState = { currentPage: 0, data: {} }

export const introductionModalSlice = createSlice({
    name: 'introductionModal',
    initialState: initialState,
    reducers: {
        nextPage: (state) => { state.currentPage += 1 },
        previousPage: (state) => { state.currentPage -= 1 },
        dataChanged: (state) => { state.data -= 1 },
    }
})



export type IntroductionModalDispatchProps = typeof introductionModalSlice.actions;
export const { nextPage, previousPage, dataChanged } = introductionModalSlice.actions;
const { reducer } = introductionModalSlice;
export { reducer as IntroductionModalReducer };
