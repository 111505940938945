import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";

import { SignoutCallbackComponent } from "redux-oidc";
import { LoadingPage } from "../../../component/loading/loading-page";
import { OAuth2Service } from "../../../services/authentication/authentication/oauth2-service";

class LogoutCallbackPage extends React.Component<RouteComponentProps<{}> & WithTranslation> {
    public render(): ReactNode {
        return (
            <SignoutCallbackComponent userManager={OAuth2Service.instance} successCallback={() =>
                window.history.replaceState({},
                    window.location.href = "https://smartbills.ca")
            } errorCallback={(error) => window.history.replaceState({},
                window.location.href = "https://smartbills.ca")}>
                <LoadingPage description={this.props.t("logoutRedirect")} />
            </SignoutCallbackComponent>);
    }
}

const CallbackScreenWithRouter = withTranslation("loading")(withRouter(LogoutCallbackPage));
export { CallbackScreenWithRouter as LogoutCallbackPage }