import detector from "i18next-browser-languagedetector";
import i18next from "i18next";
import { initReactI18next } from "react-i18next";

import headerFr from './locales/fr/header.json';
import headerEn from './locales/en/header.json';
import homeFr from './locales/fr/home.json';
import homeEn from './locales/en/home.json';
import sideMenuFr from './locales/fr/side-menu.json';
import sideMenuEn from './locales/en/side-menu.json';
import receiptsFr from './locales/fr/receipts.json';
import receiptsEn from './locales/en/receipts.json';
import receiptFr from './locales/fr/receipt.json';
import receiptEn from './locales/en/receipt.json';
import companiesFr from './locales/fr/companies.json';
import companiesEn from './locales/en/companies.json';

import settingsFr from './locales/fr/settings.json';
import settingsEn from './locales/en/settings.json';

import recentReceiptsFr from './locales/fr/components/recent-receipts.json';
import recentReceiptsEn from './locales/en/components/recent-receipts.json';
import notFoundErrorFr from './locales/fr/components/not-found-error.json';
import notFoundErrorEn from './locales/en/components/not-found-error.json';

import networkErrorFr from './locales/fr/components/network-error.json';
import networkErrorEn from './locales/en/components/network-error.json';
import loadingFr from './locales/fr/components/loading.json';
import loadingEn from './locales/en/components/loading.json';
const getCurrentLng = () => I18next.language || window.localStorage.i18nextLng || '';
export const I18next = i18next.createInstance();
I18next.use(detector)
    .use(initReactI18next)

    .init({
        detection: {
            order: ['cookie', 'localStorage', 'navigator', 'htmlTag'],
            caches: ['localStorage', 'cookie'],
        },
        lng: getCurrentLng(),
        load: "currentOnly",
        fallbackLng: "en",
        returnObjects: true,
        resources: {
            en: {
                header: headerEn,
                home: homeEn,
                "side-menu": sideMenuEn,
                "recent-receipts": recentReceiptsEn,
                receipts: receiptsEn,
                receipt: receiptEn,
                companies: companiesEn,
                settings: settingsEn,
                "not-found": notFoundErrorEn,
                "network-error": networkErrorEn,
                loading: loadingEn,
            },
            fr: {
                header: headerFr,
                home: homeFr,
                "side-menu": sideMenuFr,
                "recent-receipts": recentReceiptsFr,
                receipts: receiptsFr,
                receipt: receiptFr,
                companies: companiesFr,
                settings: settingsFr,
                "not-found": notFoundErrorFr,
                "network-error": networkErrorFr,
                loading: loadingFr,
            }
        },
        keySeparator: '.',
    })