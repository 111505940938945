import React from "react";
import { Redirect } from "react-router-dom";
import { Route } from "react-router-dom";
import { BrowserRouter, Switch } from "react-router-dom";
import { DashboardLayout } from "./layout/dashboard/dashboard.layout";
import { LoginCallbackPage } from "./pages/authentication/login/login.callback.page";
import { LoginPage } from "./pages/authentication/login/login.page";
import { LogoutCallbackPage } from "./pages/authentication/logout/logout-callback.page";
import { LogoutPage } from "./pages/authentication/logout/logout.page";
import { PrivateRoute } from "./utils/router/private-route";

export class App extends React.Component {
	public render() {
		return (
			<Switch>
				<Route exact path="/logout" component={LogoutPage}></Route>
				<Route exact path="/login/callback" component={LoginCallbackPage}></Route>
				<Route exact path="/logout/callback" component={LogoutCallbackPage}></Route>
				<Route exact path="/login" component={LoginPage}></Route>
				<PrivateRoute path="/" component={DashboardLayout}></PrivateRoute>
				<Redirect to="/login"></Redirect>
			</Switch>
		);
	}
}

