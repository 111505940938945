import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DashboardPage } from "../../component/dashboard-page/dashboard-page";


class WalletPage extends React.Component<RouteComponentProps<{}>> {
    public constructor(props: RouteComponentProps<{}>) {
        super(props);
    };
    public render(): ReactNode {
        return (
            <DashboardPage>
                    

            </DashboardPage>
        )
    }
}

const WalletPageConnected = withRouter(WalletPage);
export { WalletPageConnected as WalletPage }