import { AxiosError, AxiosResponse } from "axios";
import { WalletOverviewVM } from "../../view-models/home/overview-vm";
import { SmartbillsAPI } from "../authentication/smartbills-api";
export class WalletService {
    public static getOverview(): Promise<WalletOverviewVM> {
        return new Promise<WalletOverviewVM>((resolve, reject) => {
            SmartbillsAPI.getInstance().get(`wallet/overview`).then((response: AxiosResponse<WalletOverviewVM>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
}