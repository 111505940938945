import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import React, { ReactElement, ReactNode } from "react";
import { EuiButton, EuiLink, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiSelect, EuiText, } from "@elastic/eui";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { IntroductionModalDispatchProps, introductionModalSlice, IntroductionModalSliceState } from "../../redux/introduction-modal/introduction-modal.reducer";
import { connect } from "react-redux";
import { IntroductionModalStep } from "./introduction-modal-step";
import "./introduction-modal.scss";
export interface IntroductionModalProps {
    title?: string;
    steps: IntroductionModalStep[];
    finishProps: {
        onClick: () => void;
        text: string;
        loading: boolean;
    }
}
class IntroductionModal extends React.Component<WithTranslation & RouteComponentProps & IntroductionModalSliceState & IntroductionModalDispatchProps & IntroductionModalProps> {
    public render() {
        const { currentPage, steps } = this.props;
        const currentStep = steps[currentPage];
        return (
            <EuiOverlayMask>
                <EuiModal className="no-overflow no-exit" onClose={() => { }}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            {currentStep.title ? currentStep.title : this.props.title}
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>
                    <EuiModalBody>
                        {currentStep.body}
                    </EuiModalBody>
                    <EuiModalFooter>
                        {this.renderFooter(currentPage, currentStep, steps)}
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        );
    }

    private renderFooter(currentPage: number, currentStep: IntroductionModalStep, steps: IntroductionModalStep[]) {
        var footer: ReactElement[] = [];
        if (currentPage > 0) {
            footer.push(<EuiLink onClick={() => this.props.previousPage()}>{currentStep.previousButtonProps?.text}</EuiLink>)
        }
        if (currentPage < steps.length - 1) {
            footer.push(<EuiButton disabled={currentStep.nextButtonProps?.disabled} onClick={() => this.props.nextPage()}>{currentStep.nextButtonProps?.text} ({currentPage + 1}/{steps.length - 1})</EuiButton>)
        } else {
            footer.push(<EuiButton isLoading={this.props.finishProps.loading} onClick={this.props.finishProps.onClick}>{this.props.finishProps.text}</EuiButton>)
        }
        return footer;
    }
}

const mapStateToProps = (state: SmartbillsRootState): IntroductionModalSliceState => {
    const { introductionModal } = state;
    return introductionModal;
};
const mapDispatchToProps = introductionModalSlice.actions;


const IntroductionModalTranslated = withTranslation("introduction-modal")(connect(mapStateToProps, mapDispatchToProps)(withRouter(IntroductionModal)));
export { IntroductionModalTranslated as IntroductionModal }