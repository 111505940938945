import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { GenderEnum } from "../../models/account/gender-enum";
import { AccountService } from "../../services/account/account.service";
import { AccountVM } from "../../view-models/account/account-vm";
import { ProfileCompletedVM } from "../../view-models/account/profile-vm";
import { SmartbillsRootState } from "../smartbills.reducer";
import { AppThunk } from '../store'

export interface AccountStateProps {
    updateProfile: { loading: boolean, error: SerializedError | undefined }
    completeProfile: { loading: boolean, error: SerializedError | undefined }
    profile: {
        completed: boolean,
        gender: number,
        birthday: moment.Moment | null
    }
}
const initialState: AccountStateProps = {
    updateProfile: { loading: false, error: undefined },
    profile: {
        completed: true,
        gender: GenderEnum.UNDEFINED,
        birthday: null,
    },
    completeProfile: { loading: false, error: undefined }
}

export const updateAccount = createAsyncThunk<
    AccountVM,
    {},
    {
        rejectValue: AxiosError
    }>('account/update', async ({ }, { rejectWithValue }) => {
        try {
            const response = await AccountService.updateAccount()
            return response;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })

export const saveProfile = createAsyncThunk<
    undefined,
    undefined,
    {
        state: SmartbillsRootState,
        rejectValue: AxiosError
    }>('account/completeProfile', async (undefined, { getState, rejectWithValue }) => {
        try {
            const { account } = getState();
            var profile: ProfileCompletedVM = { birthday: account.profile.birthday, gender: account.profile.gender.valueOf() }
            const response = await AccountService.completeProfile(profile)
            return response.data;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })


export const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        birthdayChange: (state, action: PayloadAction<moment.Moment | null>) => {
            state.profile.birthday = action.payload
        },
        genderChange: (state, action: PayloadAction<number>) => {
            state.profile.gender = action.payload
        },
        clearError: (state) => {
            state.completeProfile.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(updateAccount.fulfilled, (state, { payload }) => {
            state.updateProfile.loading = false;
            state.profile.completed = payload.profileCompleted!
        })
        builder.addCase(updateAccount.pending, (state, action) => {
            state.updateProfile.loading = true;
        })
        builder.addCase(updateAccount.rejected, (state, action) => {
            state.updateProfile.loading = false;
            state.updateProfile.error = action.error;
        })

        builder.addCase(saveProfile.fulfilled, (state, { payload }) => {
            state.completeProfile.loading = false;
            state.profile.completed = true
        })
        builder.addCase(saveProfile.pending, (state, action) => {
            state.completeProfile.loading = true;
        })
        builder.addCase(saveProfile.rejected, (state, action) => {
            state.completeProfile.loading = false;
            state.completeProfile.error = action.error;
        })
    },
})

export type AccountDispatchProps = typeof accountSlice.actions;


const { reducer } = accountSlice;
export { reducer as AccountReducer };
