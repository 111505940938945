import { EuiAvatar, EuiButton, EuiEmptyPrompt, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiPageContent, EuiPanel, EuiText, EuiTitle } from "@elastic/eui";
import { bindActionCreators } from "@reduxjs/toolkit";
import { notification } from "antd";
import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { MdHourglassEmpty } from "react-icons/md";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DashboardPage } from "../../../component/dashboard-page/dashboard-page";
import { Loading } from "../../../component/loading/loading";
import { CompaniesSlice, CompaniesStateProps, getCompanies } from "../../../redux/explore/companies/companies.reducer";
import { SmartbillsRootState } from "../../../redux/smartbills.reducer";


class CompaniesPage extends React.Component<RouteComponentProps<{}> & ReceiptsProps> {
    public componentDidMount() {
        this.props.getCompanies({});
    }
    public render(): ReactNode {
        return (
            <DashboardPage title={this.props.t("title")} description={this.props.t("description")}>
                {this.renderFromState()}
            </DashboardPage>
        )
    }

    private renderFromState() {
        if (this.props.companies.loading) {
            return <Loading></Loading>
        }
        if (this.props.companies.error) {
            notification.error({ message: this.props.t("error-notification"), description: this.props.companies.error.message, placement: "bottomRight" })
            this.props.clearError("companies");
        }

        if (this.props.companies.data.length > 0) {

            return (
                <EuiFlexGrid columns={2} gutterSize="l">
                    {this.props.companies.data.map((x, key) =>
                        <EuiFlexItem key={key}>
                            <EuiPanel>
                                <EuiFlexItem className="py-1">
                                    <EuiFlexGroup direction="column" justifyContent="center" alignItems="center">
                                        <EuiAvatar size="xl" name={x.name}></EuiAvatar>
                                        <EuiTitle size="s"><h3>{x.name}</h3></EuiTitle>
                                        <a href={"https://" + x.domain} target="_blank">
                                            {x.domain}
                                        </a>
                                    </EuiFlexGroup>

                                </EuiFlexItem>
                            </EuiPanel>
                        </EuiFlexItem>
                    )}
                </EuiFlexGrid>
            )
        } else {
            return (
                <EuiPageContent verticalPosition="center" horizontalPosition="center">
                    <EuiFlexItem>
                        <EuiEmptyPrompt iconType={() => <MdHourglassEmpty size="50"></MdHourglassEmpty>}
                            title={<EuiTitle size="s"><h2>{this.props.t("no-companies.title")}</h2></EuiTitle>}
                            body={this.props.t("no-companies.body")}
                        >
                        </EuiEmptyPrompt>
                    </EuiFlexItem>
                </EuiPageContent>
            );
        }
    }
}

const mapStateToProps = (state: SmartbillsRootState): CompaniesStateProps => {
    const { explore } = state;
    return explore.companies
};
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...CompaniesSlice.actions,
        getCompanies
    }, dispatch);
};

type ReceiptsProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & WithTranslation;

const CompaniesPageConnected = withTranslation("companies")(connect(mapStateToProps, mapDispatchToProps)(withRouter(CompaniesPage)));
export { CompaniesPageConnected as CompaniesPage }