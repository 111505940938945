import React, { ReactElement } from "react";
import { EuiEmptyPrompt } from "@elastic/eui";
import { WithTranslation, withTranslation } from "react-i18next";

class NotFoundError extends React.Component<WithTranslation & { action: ReactElement[] }> {
    public render() {
        return (
            <EuiEmptyPrompt
                iconColor="warning" iconType="questionInCircle"
                title={<h1>{this.props.t("title")}</h1>}
                titleSize="m"
                body={<p>{this.props.t("body")}</p>}
                actions={this.props.action}></EuiEmptyPrompt>
        );
    }
}

const NotFoundTranslated = withTranslation("not-found")(NotFoundError)
export { NotFoundTranslated as NotFoundError }