import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ReceiptService } from "../../services/wallet/receipt.service";
import { WalletService } from "../../services/wallet/wallet.service";
import { WalletOverviewVM } from "../../view-models/home/overview-vm";
import { AppDispatch } from "../store";

export type HomeStateProps = {
    overview: { loading: boolean, error: SerializedError | undefined; data: WalletOverviewVM | null }
}
const initialState: HomeStateProps = {
    overview: { data: null, loading: false, error: undefined }
}

export const getOverview = createAsyncThunk<
    WalletOverviewVM,
    {},
    {
        dispatch: AppDispatch,
        rejectValue: AxiosError
    }>('home/overview', async ({ }, { rejectWithValue, }) => {
        try {
            const response = await WalletService.getOverview()
            return response;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })


export const homeSlice = createSlice({
    name: 'home',
    initialState: initialState,
    reducers: {
        clearError: (state) => {
            state.overview.error = undefined;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getOverview.fulfilled, (state, { payload }) => {
            state.overview.loading = false;
            state.overview.data = payload
        })
        builder.addCase(getOverview.pending, (state, action) => {
            state.overview.loading = true;
            state.overview.error = undefined;
        })
        builder.addCase(getOverview.rejected, (state, action) => {
            state.overview.loading = false;
            state.overview.error = action.error;
        })
    }
})
export type HomeDispatchProps = typeof homeSlice.actions;
export const { } = homeSlice.actions;

