import { AxiosError, AxiosResponse } from "axios";
import { ExploreCompanyVM } from "../../view-models/explore/companies/explore-company-vm";
import { RecentReceiptVM } from "../../view-models/wallet/receipts/recent-receipt-vm";
import { SmartbillsAPI } from "../authentication/smartbills-api";
export class CompanyService {
    public static getCompany(id: string): Promise<ExploreCompanyVM> {
        return new Promise<ExploreCompanyVM>((resolve, reject) => {
            SmartbillsAPI.getInstance().get(`/explore/companies/${id}`).then((response: AxiosResponse<ExploreCompanyVM>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
    public static getCompanies(): Promise<ExploreCompanyVM[]> {
        return new Promise<ExploreCompanyVM[]>((resolve, reject) => {
            SmartbillsAPI.getInstance().get(`/explore/companies`).then((response: AxiosResponse<ExploreCompanyVM[]>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
}