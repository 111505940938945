import { SBReceipt } from "@smartbills/smartbills-js";
import { AxiosError, AxiosResponse } from "axios";
import { WalletOverviewVM } from "../../view-models/home/overview-vm";
import { Paginated } from "../../view-models/utils/paginated";
import { RecentReceiptVM } from "../../view-models/wallet/receipts/recent-receipt-vm";
import { SmartbillsAPI } from "../authentication/smartbills-api";
export class ReceiptService {
    public static getRecents(): Promise<RecentReceiptVM[]> {
        return new Promise<RecentReceiptVM[]>((resolve, reject) => {
            SmartbillsAPI.getInstance().get("wallet/receipts/recent").then((response: AxiosResponse<RecentReceiptVM[]>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }

    public static getReceipt(id: string): Promise<SBReceipt> {
        return new Promise<SBReceipt>((resolve, reject) => {
            SmartbillsAPI.getInstance().get(`wallet/receipts/${id}`).then((response: AxiosResponse<SBReceipt>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
    public static getReceipts(page: number): Promise<Paginated<RecentReceiptVM>> {
        return new Promise<Paginated<RecentReceiptVM>>((resolve, reject) => {
            SmartbillsAPI.getInstance().get(`wallet/receipts?page=${page}&pageSize=${10}`).then((response: AxiosResponse<Paginated<RecentReceiptVM>>) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
}