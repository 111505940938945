import { AxiosError, AxiosResponse } from "axios";
import { AccountVM } from "../../view-models/account/account-vm";
import { ProfileCompletedVM } from "../../view-models/account/profile-vm";
import { SmartbillsAPI } from "../authentication/smartbills-api";
export class AccountService {
    public static updateAccount(): Promise<AccountVM> {
        return new Promise<AccountVM>((resolve, reject) => {
            SmartbillsAPI.getInstance().put("account").then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
    public static completeProfile(profile: ProfileCompletedVM): Promise<AxiosResponse> {
        return new Promise((resolve, reject) => {
            SmartbillsAPI.getInstance().post("account/complete", profile).then((response: AxiosResponse) => {
                resolve(response.data);
            }).catch((error: AxiosError) => {
                reject(error);
            }
            );
        });
    }
}