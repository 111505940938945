import React from "react";
import { Link, withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { NavigationHeaderProps } from "./header.props";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { UserState } from "redux-oidc";
import { connect } from "react-redux";
import { EuiHeader, EuiHeaderSection, EuiHeaderSectionItem, EuiImage, EuiPopover, EuiText, EuiButton, EuiIcon, EuiFlexGroup, EuiButtonEmpty, EuiContextMenu, EuiContextMenuPanel, EuiContextMenuItem, EuiModal, EuiModalBody, EuiModalFooter, EuiModalHeader, EuiModalHeaderTitle, EuiOverlayMask, EuiSuperSelectOption, EuiSuperSelect, EuiHideFor, EuiHeaderSectionItemButton } from "@elastic/eui";
import { DashboardLayoutStateProps } from "../../redux/dashboard-layout/dashboard-layout.state";
import { DashboardLayoutDispatchProps } from "../../redux/dashboard-layout/dashboard-layout.props";
import { toggleSideMenu, toggleTopMenu } from "../../redux/dashboard-layout/dashboard-layout.actions";
import "./header.scss";

class NavigationHeader extends React.Component<NavigationHeaderProps> {
    public constructor(props: NavigationHeaderProps) {
        super(props);
    }
    public render() {
        var items = [
            <EuiContextMenuItem
                key="settings"
                icon="gear"
                onClick={() => {
                    this.navigate("/settings")
                }}>
                {this.props.t("settings")}
            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key="account"
                icon="popout"
                href="https://accounts.smartbills.ca/dashboard"
                target="_blank">
                {this.props.t("account")}

            </EuiContextMenuItem>,
            <EuiContextMenuItem
                key="logout"
                icon="unlink"
                onClick={() => {
                    this.props.history.push("/logout")
                }}>
                {this.props.t("logout")}
            </EuiContextMenuItem>
        ];

        return (
            <EuiHeader position="fixed" style={{ paddingLeft: 20, paddingRight: 20 }}>
                <EuiHeaderSection side="right">
                    <EuiHeaderSectionItem border="none">
                        <EuiButtonEmpty className="d-block d-xl-none" style={{ marginRight: 10 }} color="text" onClick={() => this.props.toggleSideMenu(!this.props.layout.sideMenuOpen)}>
                            <EuiIcon type="menu"></EuiIcon>
                        </EuiButtonEmpty>
                    </EuiHeaderSectionItem>
                </EuiHeaderSection>
                <EuiHeaderSection side="right">
                    <EuiHeaderSectionItemButton>
                        <EuiPopover
                            id="menu"
                            button={
                                <EuiButtonEmpty onClick={() => {
                                    this.props.toggleSideMenu(false)
                                    this.props.toggleTopMenu!(true)
                                }}
                                    iconSide="right" color="text" iconType="arrowDown">
                                    <EuiText style={{ marginRight: 10 }} size="s">{this.props.oidc.user?.profile.fullname}</EuiText>
                                </EuiButtonEmpty >
                            }
                            closePopover={() => this.props.toggleTopMenu!(false)}
                            isOpen={this.props.layout.topMenuOpen}
                            anchorPosition="downCenter"
                        >
                            <EuiContextMenuPanel items={items} />
                        </EuiPopover>
                    </EuiHeaderSectionItemButton>
                </EuiHeaderSection>
            </EuiHeader>
        )
    }

    private navigate(path: string) {
        this.props.toggleTopMenu!(false);
        this.props.history.push(path);
    }
}

const mapStateToProps = (state: SmartbillsRootState): { oidc: UserState, layout: DashboardLayoutStateProps } => {
    const { oidc, layout } = state;
    return {
        oidc: oidc,
        layout: layout,
    };
};

const mapDispatchToProps = (dispatch: any): DashboardLayoutDispatchProps => {
    return {
        toggleSideMenu: (sideMenOpen: boolean) => {
            dispatch(toggleSideMenu(sideMenOpen));
        },
        toggleTopMenu: (topMenuOpen: boolean) => {
            dispatch(toggleTopMenu(topMenuOpen));
        }
    }
}

const NavigationHeaderConnected = withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation("header")(NavigationHeader)));
export { NavigationHeaderConnected as NavigationHeader }
