import { createAsyncThunk, createSlice, PayloadAction, SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { AppDispatch } from "../../store";
import { ExploreCompanyVM } from "../../../view-models/explore/companies/explore-company-vm";
import { CompanyService } from "../../../services/explore/companies.service";
export interface CompaniesStateProps {
    company: { loading: boolean, error: SerializedError | undefined, data: ExploreCompanyVM | null }
    companies: { loading: boolean, error: SerializedError | undefined, data: ExploreCompanyVM[] }

}
const initialState: CompaniesStateProps = {
    company: { loading: false, error: undefined, data: null },
    companies: { loading: false, error: undefined, data: [] }
}

export const getCompany = createAsyncThunk<
    ExploreCompanyVM,
    string,
    {
        dispatch: AppDispatch,
        rejectValue: AxiosError
    }>('wallet/companies/getCompany', async (id, { rejectWithValue, }) => {
        try {
            const response = await CompanyService.getCompany(id)
            return response;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })

export const getCompanies = createAsyncThunk<
    ExploreCompanyVM[],
    {},
    {
        dispatch: AppDispatch,
        rejectValue: AxiosError
    }>('wallet/companies/getCompanies', async ({ }, { rejectWithValue, }) => {
        try {
            const response = await CompanyService.getCompanies()
            return response;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })

export const CompaniesSlice = createSlice({
    name: 'wallet/companies',
    initialState: initialState,
    reducers: {
        clearError: (state, action: PayloadAction<"company" | "companies">) => {
            state[action.payload].error = undefined
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getCompany.fulfilled, (state, { payload }) => {
            state.company.loading = false;
            state.company.data = payload
        })
        builder.addCase(getCompany.pending, (state, action) => {
            state.company.loading = true;
            state.company.error = undefined;
        })
        builder.addCase(getCompany.rejected, (state, action) => {
            state.company.loading = false;
            state.company.error = action.error;
        })
        builder.addCase(getCompanies.fulfilled, (state, { payload }) => {
            state.companies.loading = false;
            state.companies.data = payload
        })
        builder.addCase(getCompanies.pending, (state, action) => {
            state.companies.error = undefined;
            state.companies.loading = true;
        })
        builder.addCase(getCompanies.rejected, (state, action) => {
            state.companies.loading = false;
            state.companies.error = action.error
        })
    },
});

export type CompaniesDispatchProps = typeof CompaniesSlice.actions;


const { reducer } = CompaniesSlice;
export { reducer as CompaniesReducer };
