import { ActionCreatorWithPayload, createAsyncThunk, createSlice, PayloadAction, SerializedError, unwrapResult } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { ReceiptService } from "../../../services/wallet/receipt.service";
import { RecentReceiptVM } from "../../../view-models/wallet/receipts/recent-receipt-vm";
import { SmartbillsRootState } from "../../smartbills.reducer";
import { AppDispatch } from "../../store";
import { SBReceipt } from "@smartbills/smartbills-js";
import { Paginated } from "../../../view-models/utils/paginated";
export interface ReceiptsStateProps {
    recentReceipts: { loading: boolean, error: SerializedError | undefined, data: RecentReceiptVM[] }
    receipt: { loading: boolean, error: SerializedError | undefined, data: SBReceipt | null }
    receipts: { loading: boolean, error: SerializedError | undefined, data: Paginated<RecentReceiptVM> | null, activePage: number }
}
const initialState: ReceiptsStateProps = {
    recentReceipts: { loading: false, error: undefined, data: [] },
    receipt: { loading: false, error: undefined, data: null },
    receipts: { loading: false, error: undefined, data: null, activePage: 0, },
}

export const getRecentReceipts = createAsyncThunk<
    RecentReceiptVM[],
    {},
    {
        dispatch: AppDispatch,
        rejectValue: AxiosError
    }>('wallet/receipts/recent', async ({ }, { rejectWithValue, }) => {
        try {
            const response = await ReceiptService.getRecents()
            return response;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })

export const getReceipt = createAsyncThunk<
    SBReceipt,
    string,
    {
        dispatch: AppDispatch,
        rejectValue: AxiosError
    }>('wallet/receipts/get', async (id, { rejectWithValue, }) => {
        try {
            const response = await ReceiptService.getReceipt(id)
            return response;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })

export const getReceipts = createAsyncThunk<
    Paginated<RecentReceiptVM>,
    {},
    {
        dispatch: AppDispatch,
        state: SmartbillsRootState,
        rejectValue: AxiosError
    }>('wallet/receipts/list', async ({ }, { getState, rejectWithValue, }) => {
        try {
            const { activePage } = getState().wallet.receipts.receipts;
            const response = await ReceiptService.getReceipts(activePage + 1)
            return response;
        } catch (exception) {
            let error: AxiosError = exception
            if (!error.response) {
                throw exception;
            }
            return rejectWithValue(error.response.data)
        }
    })

export const receiptsSlice = createSlice({
    name: 'wallet/receipts',
    initialState: initialState,
    reducers: {
        updatePage: (state, action: PayloadAction<number>,) => {
            state.receipts.activePage = action.payload
        },
        clearError: (state, action: PayloadAction<"receipt" | "receipts">) => {
            state[action.payload].error = undefined
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getRecentReceipts.fulfilled, (state, { payload }) => {
            state.recentReceipts.loading = false;
            state.recentReceipts.data = payload
        })
        builder.addCase(getRecentReceipts.pending, (state, action) => {
            state.recentReceipts.loading = true;
            state.recentReceipts.error = undefined;
        })
        builder.addCase(getRecentReceipts.rejected, (state, action) => {
            state.recentReceipts.loading = false;
            state.recentReceipts.error = action.error
        })

        builder.addCase(getReceipt.fulfilled, (state, { payload }) => {
            state.receipt.loading = false;
            state.receipt.data = payload
        })
        builder.addCase(getReceipt.pending, (state, action) => {
            state.receipt.error = undefined;
            state.receipt.loading = true;
        })
        builder.addCase(getReceipt.rejected, (state, action) => {
            state.receipt.loading = false;
            state.receipt.error = action.error
        })
        builder.addCase(getReceipts.fulfilled, (state, { payload }) => {
            state.receipts.loading = false;
            state.receipts.data = payload
        })
        builder.addCase(getReceipts.pending, (state, action) => {
            state.receipts.loading = true;
            state.receipts.error = undefined;
        })
        builder.addCase(getReceipts.rejected, (state, action) => {
            state.receipts.loading = false;
            state.receipts.error = action.error
        })
    },
});

export type ReceiptsDispatchProps = typeof receiptsSlice.actions;


const { reducer } = receiptsSlice;
export { reducer as ReceiptsReducer };
