import React, { Fragment, ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ImStack } from "react-icons/im";
import { WithTranslation, withTranslation } from "react-i18next";
import { SideMenuState } from "./side-menu.state";
import { EuiCollapsibleNav, EuiCollapsibleNavGroup, EuiIcon, EuiListGroup } from "@elastic/eui";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { toggleSideMenu } from "../../redux/dashboard-layout/dashboard-layout.actions";
import { DashboardLayoutDispatchProps } from "../../redux/dashboard-layout/dashboard-layout.props";
import { DashboardLayoutStateProps } from "../../redux/dashboard-layout/dashboard-layout.state";
import { connect } from "react-redux";
import "./side-menu.scss"
import { BiReceipt } from "react-icons/bi";
import { AiOutlineShop } from "react-icons/ai";

class SideMenu extends React.Component<RouteComponentProps & { layout: DashboardLayoutStateProps } & DashboardLayoutDispatchProps & WithTranslation, SideMenuState> {
    public constructor(props: RouteComponentProps & { layout: DashboardLayoutStateProps } & DashboardLayoutDispatchProps & WithTranslation) {
        super(props);
        this.state = {
            collapsed: false,
        };
    }
    public render(): ReactNode {
        return (
            <EuiCollapsibleNav
                isDocked
                dockedBreakpoint={1200}
                showButtonIfDocked={false}
                onClose={() => {
                    this.props.toggleSideMenu(false)
                }} isOpen={this.props.layout.sideMenuOpen}>
                {
                    this.renderAppMenu()
                }
            </EuiCollapsibleNav>
        )
    }

    private renderAppMenu() {
        return (
            <Fragment>
                <EuiCollapsibleNavGroup
                    className="no-children"
                    initialIsOpen={this.isOpen("/home")}
                    title={this.props.t("home")}
                    iconType="home"
                    onClick={() => this.navigate("/")}
                    isCollapsible={true} />
                <EuiCollapsibleNavGroup
                    iconType={() => <ImStack size={24} />}
                    className="menu-item"
                    initialIsOpen={this.isOpen("/wallet")}
                    title={this.props.t("wallet.title")}
                    isCollapsible={true}>
                    <EuiListGroup
                        listItems={[
                            {
                                label: this.props.t("wallet.receipts"),
                                onClick: () => this.navigate('/wallet/receipts'),
                                icon: <BiReceipt />,
                                isActive: this.isActive("/wallet/receipts")
                            },
                        ]}
                        maxWidth="none"
                        color="subdued"
                        gutterSize="none"
                        size="m"
                    />
                </EuiCollapsibleNavGroup>
                <EuiCollapsibleNavGroup
                    title={this.props.t("explore.title")}
                    className="menu-item"
                    iconType="search"
                    arrowDisplay="right"
                    initialIsOpen={this.isOpen("/explore")}
                    isCollapsible={true}>
                    <EuiListGroup
                        listItems={[
                            {
                                label: this.props.t("explore.companies"),
                                onClick: () => this.navigate('/explore/companies'),
                                icon: <AiOutlineShop />,
                                isActive: this.isActive("/explore/companies")
                            },
                        ]}
                        maxWidth="none"
                        color="subdued"
                        gutterSize="none"
                        size="m"
                    />
                </EuiCollapsibleNavGroup>
            </Fragment >
        )
    }

    public navigate(path: string) {
        this.props.history.push(path);
        this.props.toggleSideMenu(false);
    }

    private isActive(key: string) {
        const currentActive = this.props.location.pathname;
        return key == currentActive;
    }
    private isOpen(key: string) {
        const currentActive = this.props.location.pathname;
        return currentActive.includes(key);
    }
}

const mapStateToProps = (state: SmartbillsRootState): { layout: DashboardLayoutStateProps } => {
    const { layout } = state;
    return {
        layout: layout,
    };
};

const mapDispatchToProps = (dispatch: any): DashboardLayoutDispatchProps => {
    return {
        toggleSideMenu: (sideMenOpen: boolean) => {
            dispatch(toggleSideMenu(sideMenOpen));
        }
    }
}


const SubMenuWithRouter = connect(mapStateToProps, mapDispatchToProps)(withRouter(withTranslation("side-menu")(SideMenu)));
export { SubMenuWithRouter as SideMenu }
