import React, { Fragment, ReactElement } from "react";

import { EuiPageBody, EuiPageHeader, EuiPageContentBody, EuiPageHeaderSection, EuiTitle, EuiText, EuiBreadcrumbs, EuiBreadcrumb, EuiSpacer, } from "@elastic/eui";
import "./dashboard-page.scss";
export class DashboardPage extends React.Component<{ title?: string, centered?: boolean, ability?: ReactElement, description?: string; breadcrumb?: { responsive?: boolean, breadcrumbs: EuiBreadcrumb[] } }> {
    public render() {
        return (
            <EuiPageBody className={this.getCenteredStyle()}>
                {this.renderHeader()}
                <EuiPageContentBody >
                    {this.props.children}
                </EuiPageContentBody>
            </EuiPageBody>
        )
    }

    private getCenteredStyle() {
        if (this.props.centered) {
            return "page-centered";
        }
    }

    private renderBreadcrumb() {
        if (this.props.breadcrumb) {
            return (
                <Fragment>
                    <EuiBreadcrumbs responsive={this.props.breadcrumb.responsive} breadcrumbs={
                        this.props.breadcrumb.breadcrumbs
                    }>
                    </EuiBreadcrumbs>
                    {this.props.description && <EuiSpacer></EuiSpacer>}
                </Fragment>
            )
        }
    }

    private renderHeader() {
        if (this.props.title) {
            return (
                <EuiPageHeader responsive={false}>
                    <EuiPageHeaderSection>
                        <EuiTitle size="l">
                            <h1>{this.props.title}</h1>
                        </EuiTitle>
                        {this.renderBreadcrumb()}
                        {this.props.description && <EuiText size="m">
                            <p>{this.props.description}</p>
                        </EuiText>}

                    </EuiPageHeaderSection>
                    { this.props.ability &&
                        <EuiPageHeaderSection >{this.props.ability}</EuiPageHeaderSection>
                    }
                </EuiPageHeader>
            );
        }
    }
}
