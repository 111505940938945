import { EuiAvatar, EuiComment, EuiCommentList, EuiImage, EuiFlexGrid, EuiFlexGroup, EuiFlexItem, EuiFormRow, EuiHorizontalRule, EuiIcon, EuiLink, EuiPanel, EuiText, EuiTitle, EuiOverlayMask } from "@elastic/eui";
import { bindActionCreators } from "@reduxjs/toolkit";
import React, { Fragment, ReactNode } from "react";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DashboardPage } from "../../component/dashboard-page/dashboard-page";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { ReceiptsStateProps, getReceipt, receiptsSlice } from "../../redux/wallet/receipts/receipts.reducer";
import { SBCompanyCustomer, SBReceipt, SBReceiptItem, SBReceiptTax, SBTransaction } from "@smartbills/smartbills-js";
import moment from "moment";
import "./receipt.scss";
import { FiCreditCard } from "react-icons/fi";
import { WithTranslation, withTranslation } from "react-i18next";
import { notification } from "antd";
import { Loading } from "../../component/loading/loading";
class ReceiptPage extends React.Component<ReceiptProps & RouteComponentProps<{ id: string }>> {
    public constructor(props: RouteComponentProps<{ id: string }> & ReceiptProps) {
        super(props);
    };
    public componentDidMount() {
        this.props.getReceipt(this.props.match.params.id);
    }
    public render(): ReactNode {
        var { data: receipt } = this.props.receipt;
        if (this.props.receipt.loading) {
            return <Loading></Loading>
        }
        this.renderErrors();
        return (
            <DashboardPage title={receipt?.company.name} breadcrumb={{ breadcrumbs: [{ text: this.props.t("breadcrumb.receipts"), onClick: () => this.props.history.push("/wallet/receipts") }, { text: receipt?.company.name }], responsive: false }}>
                <EuiFlexGroup className="flex-column ">
                    <EuiFlexItem>
                        <EuiPanel className="panel" paddingSize="l" >
                            <EuiFlexGroup direction="column">
                                <EuiFlexItem>
                                    <EuiPanel style={{ order: 0, border: 0, boxShadow: "none" }}>
                                        <EuiFlexGroup direction="column" alignItems="center" justifyContent="center">
                                            <EuiAvatar size="xl" name={receipt?.company?.name ? receipt?.company?.name : ""}></EuiAvatar>
                                            <EuiTitle><h1>{receipt?.company?.name}</h1></EuiTitle>
                                            <EuiText>{receipt?.company.hqPhoneNumber}</EuiText>
                                        </EuiFlexGroup>
                                    </EuiPanel>
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    <EuiFlexGroup className="mt-3" direction="column" alignItems="center" justifyContent="center">
                                        <EuiText>{moment(receipt?.createdAt).format('LLL')}</EuiText>
                                        <EuiLink target="_blank" external href={receipt?.receiptMetadata?.url}>Voir en ligne</EuiLink>
                                    </EuiFlexGroup>
                                </EuiFlexItem>
                                <EuiHorizontalRule ></EuiHorizontalRule>
                                {this.renderItems()}
                                <EuiHorizontalRule ></EuiHorizontalRule>
                                <EuiFlexItem style={{ alignSelf: "flex-end" }}>
                                    <EuiText>{this.props.t("subtotal")}: {receipt?.total?.toFixed(2)} $</EuiText>
                                    {receipt?.totalDiscount &&
                                        <EuiText>{this.props.t("discount")}: {receipt?.totalDiscount?.toFixed(2)} $</EuiText>
                                    }
                                    <EuiText style={{ alignSelf: "flex-end" }}>{this.props.t("taxes")}: {receipt?.totalTaxes.toFixed(2)} $</EuiText>
                                    <EuiText style={{ alignSelf: "flex-end" }}>{this.props.t("total")}: {receipt?.total.toFixed(2)} $</EuiText>
                                </EuiFlexItem>

                            </EuiFlexGroup>

                        </EuiPanel>
                    </EuiFlexItem>
                    <EuiFlexItem>
                        <EuiFlexGroup>
                            {receipt?.shippingAddress &&
                                <EuiFlexItem >
                                    <EuiPanel>
                                        <EuiIcon type="package" size="xxl"></EuiIcon>
                                        <EuiTitle size="xs"><h1>{this.props.t("shipping")}</h1></EuiTitle>
                                        <EuiHorizontalRule margin="xs"></EuiHorizontalRule>
                                        <EuiText>{receipt?.shippingAddress.line1}</EuiText>
                                        <EuiText>{receipt?.shippingAddress.line2}</EuiText>
                                        <EuiText>{receipt?.shippingAddress.city}, {receipt?.shippingAddress.state}</EuiText>
                                        <EuiText>{receipt?.shippingAddress.countryCode}</EuiText>
                                    </EuiPanel>
                                </EuiFlexItem>
                            }

                            {receipt?.billingAddress &&
                                <EuiFlexItem >
                                    <EuiPanel>
                                        <FiCreditCard size={35}></FiCreditCard>
                                        <EuiTitle size="xs"><h1>{this.props.t("billing")}</h1></EuiTitle>
                                        <EuiHorizontalRule margin="xs"></EuiHorizontalRule>
                                        <EuiText>{(receipt?.billingAddress as any).firstName} {(receipt?.billingAddress as any).lastName}</EuiText>
                                        <EuiText>{(receipt?.billingAddress as any).phone}</EuiText>
                                        <EuiText>{(receipt?.billingAddress as any).address.line1}</EuiText>
                                        <EuiText>{(receipt?.billingAddress as any).address.line2}</EuiText>
                                        <EuiText>{(receipt?.billingAddress as any).address.city}, {(receipt?.billingAddress as any).address.state}</EuiText>
                                        <EuiText>{(receipt?.billingAddress as any).address.countryCode}</EuiText>
                                    </EuiPanel>
                                </EuiFlexItem>
                            }
                        </EuiFlexGroup>
                    </EuiFlexItem>
                    { /*
                        <EuiFlexItem>
                            <EuiFlexGroup>
                                <EuiFlexItem>
                                    {this.renderTransactions()}
                                </EuiFlexItem>
                                <EuiFlexItem>
                                    {this.renderPaymentDetail()}
                                </EuiFlexItem>
                            </EuiFlexGroup>
                        </EuiFlexItem>
                    */}
                </EuiFlexGroup>
            </DashboardPage >

        )
    }
    private renderErrors() {
        if (this.props.receipt.error) {
            switch (this.props.receipt.error?.code) {
                case "400":
                case "404":
                    this.props.history.push("/404")
                    break;
                case "500":
                    notification.error({ message: "", description: this.props.receipt.error.message })
                    this.props.clearError("receipt");
                    break;
                case null:
                    break;
                default:
                    if (this.props.receipt.error?.message == "Network Error")
                        this.props.history.push("/network-error")
                    break;
            }
        }
    }

    private renderPaymentDetail() {
        var creditCards: SBTransaction[] = this.props.receipt.data?.transactions.filter(x => x.paymentDetail?.creditCard)!
        return (
            <EuiPanel >
                <EuiTitle size="xs"><h1>Payment detail</h1></EuiTitle>
                <EuiHorizontalRule margin="xs"></EuiHorizontalRule>
                {creditCards?.map((x, key) =>
                    <Fragment key={key}>
                        <EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiText>
                                </EuiText>
                            </EuiFlexItem>
                            <EuiFlexItem>
                                <EuiText>
                                    {x.gateway}
                                </EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </Fragment>
                )}

            </EuiPanel>
        )
    }

    private renderItems() {
        return this.props.receipt.data?.items.map((x, key) => {
            if (x.product) {
                return (
                    <EuiPanel key={key} style={{ order: 0, border: 0, boxShadow: "none" }} >
                        <EuiFlexGroup justifyContent="center" alignItems="center" responsive={false}>
                            <EuiFlexItem grow={false}>
                                {this.renderProductImage(x)}
                            </EuiFlexItem>
                            <EuiFlexItem grow={4}>
                                {x.quantity} x
                            </EuiFlexItem>
                            <EuiFlexItem grow={4}>
                                {x.price.toFixed(2)} $
                            </EuiFlexItem>
                            <EuiFlexItem grow={10}>
                                <EuiText size="s">{x.product.name}</EuiText>
                                {x.variant &&
                                    <EuiText size="xs">{x.variant.name}</EuiText>
                                }
                            </EuiFlexItem>
                            <EuiFlexItem grow={false} style={{ alignItems: "flex-end" }}>
                                <EuiText ><b>{x.subTotal.toFixed(2)} $</b></EuiText>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </EuiPanel >
                )
            }
        })
    }

    private renderProductImage(item: SBReceiptItem) {
        if (item.variant) {
            return (
                <EuiImage allowFullScreen alt="" size={75} url={item.product.images[item.variant.position - 1].src}></ EuiImage>
            )
        }
        else if (item.product) {
            return (
                <EuiAvatar size="xl" type="space" name="?" imageUrl={item.product.images[0]?.src}></ EuiAvatar>
            )
        } else {
            return <EuiAvatar size="s" name="?"></ EuiAvatar>
        }
    }

    private renderTaxes() {
        return this.props.receipt.data?.taxes.map((x, key) => {
            return (
                <EuiFlexItem key={key}>
                    <EuiText><b>{x.name} ({(x.rate * 100).toFixed(3)})%</b> : {x.price}$</EuiText>
                </EuiFlexItem>
            );
        })
    }

    private renderTransactions() {
        return (
            <EuiPanel >
                <EuiTitle size="xs"><h1>Transaction history</h1></EuiTitle>
                <EuiHorizontalRule margin="xs"></EuiHorizontalRule>
                {this.props.receipt.data?.transactions.map((x: SBTransaction, key) => {
                    return (
                        <EuiComment
                            key={key}
                            type="update"
                            timestamp={moment.utc(x.createdAt).local().fromNow()}
                            username={x.kind}
                            event={x.status}
                        >
                        </EuiComment >
                    )
                })
                }
            </EuiPanel>
        )
    }
}


const mapStateToProps = (state: SmartbillsRootState): ReceiptsStateProps => {
    const { wallet } = state;
    return wallet.receipts
};
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        ...receiptsSlice.actions,
        getReceipt
    }, dispatch);
};

type ReceiptProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & WithTranslation;


const ReceiptPageConnected = withTranslation("receipt")((connect(mapStateToProps, mapDispatchToProps)(withRouter(ReceiptPage))));
export { ReceiptPageConnected as ReceiptPage }