import React, { Fragment } from "react";
import { LoadingProps } from "./loading.props";
import { EuiLoadingSpinner, EuiPage, EuiPageBody, EuiPageContent, EuiEmptyPrompt } from "@elastic/eui";

export class Loading extends React.Component<LoadingProps> {

    public render() {
        return (
            <EuiEmptyPrompt
                iconType={this.props.icon}
                iconColor={this.props.iconColor}
                title={<h1>{this.props.title}</h1>}
                titleSize="m"
                body={
                    <Fragment>
                        <p>{this.props.description}</p>
                        <EuiLoadingSpinner size="xl"></EuiLoadingSpinner>
                    </Fragment>
                }
            />
        );
    }
}