import React, { Fragment, ReactNode } from 'react';
import { EuiFlexGroup, EuiFlexItem, EuiTitle, EuiPanel, EuiText, EuiAvatar, EuiHorizontalRule, EuiLoadingSpinner } from '@elastic/eui';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { receiptsSlice, ReceiptsStateProps, getRecentReceipts } from '../../../redux/wallet/receipts/receipts.reducer';
import { RecentReceiptVM } from '../../../view-models/wallet/receipts/recent-receipt-vm';
import { Link } from 'react-router-dom';
import { FiChevronRight } from "react-icons/fi";
import { WithTranslation, withTranslation } from 'react-i18next';
class RecentReceipts extends React.Component<RecentsReceiptsProps> {
    public componentDidMount() {
        this.props.getRecentReceipts({});
    }
    public render() {
        return (
            <Fragment>
                <EuiPanel>
                    <EuiFlexGroup alignItems="center" className="pl-1 pr-3">
                        <EuiFlexItem>
                            <EuiTitle size="s" ><h2>{this.props.t("title")}</h2></EuiTitle>
                            <EuiText>{this.props.t("description")}</EuiText>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                    <EuiHorizontalRule></EuiHorizontalRule>
                    {this.renderContentFromState()}
                </EuiPanel>
            </Fragment>
        );
    }

    private renderContentFromState() {
        if (this.props.recentReceipts.loading) {
            return (
                <EuiFlexGroup justifyContent="center" alignItems="center">
                    <EuiLoadingSpinner size="xl"></EuiLoadingSpinner>
                </EuiFlexGroup>
            )
        } else {
            if (this.props.recentReceipts.data == null || this.props.recentReceipts.data.length == 0) {
                return (
                    this.renderEmpty()
                )
            } else {
                return (
                    <Fragment>
                        {this.renderReceipts()}
                        < EuiFlexGroup className="py-3" justifyContent="center" alignItems="center" >
                            <Link to="/wallet/receipts"> <span> {this.props.t("see-all")} <FiChevronRight className="align-middle"> </FiChevronRight></span></Link>
                        </EuiFlexGroup >
                    </Fragment>)
            }
        }
    }

    private renderEmpty() {
        return <EuiText>{this.props.t("no-receipts")}</EuiText>
    }


    private renderReceipts(): ReactNode {
        return (
            <EuiFlexGroup direction="column">
                {
                    this.props.recentReceipts.data.map((receipt: RecentReceiptVM, key) => {
                        return (
                            <EuiFlexItem key={key}>
                                <EuiFlexGroup justifyContent="spaceBetween" alignItems="center">
                                    <EuiFlexItem className="align-items-start" grow={false} >
                                        <EuiFlexGroup className="align-items-center" responsive={false}>
                                            <EuiFlexItem  >
                                                <EuiAvatar type="space" size="l" name={receipt.company.name}></EuiAvatar>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false} >
                                                <EuiTitle size="s">
                                                    <h4>{receipt.company.name}</h4>
                                                </EuiTitle>
                                            </EuiFlexItem>

                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                    <EuiFlexItem className="align-items-start" grow={false}>
                                        <EuiFlexGroup className="align-items-center" responsive={false}>
                                            <EuiFlexItem grow={false} >
                                                <EuiText>{receipt.total.toFixed(2)} $</EuiText>
                                            </EuiFlexItem>
                                            <EuiFlexItem grow={false} >
                                                <Link to={`/wallet/receipts/${receipt.id}`}> <span> {this.props.t("see")} <FiChevronRight className="align-middle"> </FiChevronRight></span></Link>

                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiFlexItem>
                                </EuiFlexGroup>
                            </EuiFlexItem>
                        );
                    })
                }
            </EuiFlexGroup >
        )
    }
}

const mapStateToProps = (state: any): ReceiptsStateProps => {
    const { wallet } = state;
    return wallet.receipts
};


const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        getRecentReceipts,
        ...receiptsSlice.actions
    }, dispatch);
};

type RecentsReceiptsProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & WithTranslation;

const RecentReceiptsConnected = withTranslation("recent-receipts")(connect(mapStateToProps, mapDispatchToProps)(RecentReceipts));
export { RecentReceiptsConnected as RecentReceipts }