import { EuiFlexGroup, EuiFlexItem, EuiPanel, EuiSelect, EuiText, EuiTitle } from "@elastic/eui";
import React, { ReactNode } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { DashboardPage } from "../../component/dashboard-page/dashboard-page";
import moment from 'moment'
import 'moment/locale/fr'
import { notification } from "antd";

class SettingsPage extends React.Component<RouteComponentProps<{}> & WithTranslation> {
    public render(): ReactNode {
        return (
            <DashboardPage title={this.props.t("title")} >
                <EuiPanel>
                    <EuiFlexGroup responsive={false} justifyContent="spaceBetween" alignItems="center">
                        <EuiFlexItem>
                            <EuiText size="m">
                                {this.props.t("language.title")}
                            </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                            <EuiSelect defaultValue={this.props.i18n.language} onChange={(event) => { this.changeLanguage(event.target.value) }}
                                options={[{
                                    text: "en"
                                },
                                {
                                    text: "fr"
                                }]}></EuiSelect>
                        </EuiFlexItem>
                    </EuiFlexGroup>
                </EuiPanel>
            </DashboardPage>
        )
    }

    private changeLanguage(lang: string): void {
        this.props.i18n.changeLanguage(lang)
        moment.locale(lang)
        notification.success({ message: this.props.t("language.notification.title"), description: this.props.t("language.notification.message", { lang: lang }), placement: "bottomRight" })
    }
}

const SettingsPageConnected = withTranslation("settings")(withRouter(SettingsPage));
export { SettingsPageConnected as SettingsPage }