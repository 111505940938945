import React, { ReactNode } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { NetworkError } from "../../component/errors/network-error";
import { NotFoundError } from "../../component/errors/not-found-error";
import { CompaniesPage } from "../../pages/explore/companies/companies";
import { HomePage } from "../../pages/home/home.page";
import { SettingsPage } from "../../pages/settings/settings";
import { ReceiptPage } from "../../pages/wallet/receipt";
import { ReceiptsPage } from "../../pages/wallet/receipts";
import { WalletPage } from "../../pages/wallet/wallet";
export class Router extends React.Component {
    public render(): ReactNode {
        return this.renderFromState()
    }

    public renderFromState() {
        return (
            <Switch>
                <Route exact path="/" component={HomePage} />
                <Route exact path="/wallet" component={WalletPage} />
                <Route exact path="/wallet/receipts" component={ReceiptsPage} />
                <Route exact path="/wallet/receipts/:id" component={ReceiptPage} />
                <Route exact path="/explore/companies" component={CompaniesPage} />
                <Route exact path="/settings" component={SettingsPage} />
                <Route exact path="/network-error" component={NetworkError} />
                <Route exact path="/404" component={NotFoundError} />
                <Redirect to="/404"></Redirect>
            </Switch>
        )
    }
}
