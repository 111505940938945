import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";

import { CallbackComponent } from "redux-oidc";
import mixpanel from "mixpanel-browser";
import { OAuth2Service } from "../../../services/authentication/authentication/oauth2-service";
import { LoadingPage } from "../../../component/loading/loading-page";

class LoginCallbackPage extends React.Component<WithTranslation & RouteComponentProps<{}>> {
    public constructor(props: WithTranslation & RouteComponentProps<{}>) {
        super(props);
    };

    public render(): ReactNode {
        return (<CallbackComponent userManager={OAuth2Service.instance} successCallback={(user) => {

            this.props.history.push("/")
        }} errorCallback={(error) => {
            this.props.history.push("/error")
        }}>
            <LoadingPage description={this.props.t("appRedirect")} />
        </CallbackComponent>);
    }

    public onSuccessCallback() {
        this.props.history.push("/");
    }
}

const LoginCallbackPageWithRouter = withRouter(withTranslation("loading")(LoginCallbackPage));
export { LoginCallbackPageWithRouter as LoginCallbackPage } 