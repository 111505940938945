import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";

import mixpanel from "mixpanel-browser";
import { OAuth2Service } from "../../../services/authentication/authentication/oauth2-service";

class LogoutPage extends React.Component<RouteComponentProps<{}>> {
    public constructor(props: RouteComponentProps<{}>) {
        super(props);
    };
    componentDidMount() {
        OAuth2Service.instance.signoutRedirect();
    }

    public render(): ReactNode {
        return (
            null
        )
    }
}

const CallbackScreenWithRouter = withRouter(LogoutPage);
export { CallbackScreenWithRouter as LogoutPage }