import { DashboardLayoutActionTypes } from "./dashboard-layout.types";
import { DashboardLayoutPayload } from "./dashboard-layout.state";

export interface DashboardLayoutAction {
    type: DashboardLayoutActionTypes;
    payload: DashboardLayoutPayload;
}

export const toggleSideMenu = (sideMenuOpen: boolean): DashboardLayoutAction => {
    return {
        type: DashboardLayoutActionTypes.TOGGLE_SIDE_MENU,
        payload: {
            sideMenuOpen: sideMenuOpen
        }
    }
};

export const toggleTopMenu = (topMenuOpen: boolean): DashboardLayoutAction => {
    return {
        type: DashboardLayoutActionTypes.TOGGLE_TOP_MENU,
        payload: {
            topMenuOpen: topMenuOpen
        }
    }
};