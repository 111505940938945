
import { Store } from 'redux';
import { loadUser } from "redux-oidc";
import logger from 'redux-logger'
import thunk, { ThunkAction } from 'redux-thunk';
import { smartbillsReducer, SmartbillsRootState } from './smartbills.reducer';
import { OAuth2Service } from '../services/authentication/authentication/oauth2-service';
import { configureStore, Action } from '@reduxjs/toolkit'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router';
export const history = createBrowserHistory()

var store: Store<SmartbillsRootState>;

if (process.env.NODE_ENV === "development") {

  store = configureStore({
    reducer: smartbillsReducer(history),
    middleware: [thunk, logger, routerMiddleware(history)]
  })

  if (module.hot) {
    module.hot.accept('./smartbills.reducer', () => {
      store.replaceReducer(smartbillsReducer(history))
    })

  }
} else {
  store = configureStore({ reducer: smartbillsReducer(history), middleware: [thunk, routerMiddleware(history)] })
}

loadUser(store, OAuth2Service.instance)


export type AppDispatch = typeof store.dispatch
export type AppThunk = ThunkAction<void, SmartbillsRootState, unknown, Action<string>>
export { store };