import React, { MouseEventHandler, ReactElement } from "react";
import { EuiEmptyPrompt, EuiButton } from "@elastic/eui";
import { WithTranslation, withTranslation } from "react-i18next";

class NetworkError extends React.Component<WithTranslation & { action: ReactElement[] }> {
    public render() {
        return (
            <EuiEmptyPrompt
                iconType="offline"
                iconColor="#ff5757"
                title={<h1>{this.props.t("title")}</h1>}
                titleSize="m"
                body={<p>{this.props.t("body")}</p>}
                actions={this.props.action}></EuiEmptyPrompt>
        );
    }
}
const NetworkErrorTranslated = withTranslation("network-error")(NetworkError)
export { NetworkErrorTranslated as NetworkError }