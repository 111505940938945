import { EuiAvatar, EuiButton, EuiEmptyPrompt, EuiFlexGroup, EuiFlexItem, EuiIcon, EuiPageContent, EuiPagination, EuiPanel, EuiText, EuiTitle } from "@elastic/eui";
import { bindActionCreators } from "@reduxjs/toolkit";
import moment from "moment";
import React, { Fragment, ReactNode } from "react";
import { connect } from "react-redux";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { DashboardPage } from "../../component/dashboard-page/dashboard-page";
import { SmartbillsRootState } from "../../redux/smartbills.reducer";
import { ReceiptsStateProps, getReceipts, receiptsSlice } from "../../redux/wallet/receipts/receipts.reducer";
import _ from "lodash";
import { RecentReceiptVM } from "../../view-models/wallet/receipts/recent-receipt-vm";
import { FiChevronRight } from "react-icons/fi";
import { push } from 'connected-react-router'
import { Loading } from "../../component/loading/loading";
import { withTranslation, WithTranslation } from "react-i18next";
import { notification } from "antd";
import { MdHourglassEmpty } from "react-icons/md";
class ReceiptsPage extends React.Component<RouteComponentProps<{}> & ReceiptsProps> {
    public componentDidMount() {
        this.props.getReceipts({});
    }
    public render(): ReactNode {
        var receipts = _.groupBy(this.props.receipts.data?.data, x => moment.utc(x.date).local().format("LL"));
        return (
            <DashboardPage title={this.props.t("title")}>
                {this.renderReceipts(receipts)}
                {this.renderError()}
            </DashboardPage>
        )
    }

    private renderError() {
        if (this.props.receipts.error) {
            notification.error({ message: this.props.t("error-notification"), description: this.props.receipts.error.message, placement: "bottomRight" })
            this.props.clearError("receipts");
        }
    }

    private renderReceipts(receipts: _.Dictionary<RecentReceiptVM[]>) {
        if (this.props.receipts.loading) {
            return <Loading></Loading>
        } else {
            if (this.props.receipts.data?.data && this.props.receipts.data?.data.length! > 0) {
                var pagination = this.props.receipts.data?.paging;
                return (
                    <Fragment>
                        <EuiFlexGroup direction="column" className="p-3">
                            {_.map((receipts), (receipts, date) => {
                                return (
                                    <Fragment>
                                        <EuiTitle size="s" className="mt-3"><h2>{date}</h2></EuiTitle>
                                        {
                                            receipts.map((receipt, key) =>
                                                <EuiPanel key={key} className="mb-3">
                                                    <EuiFlexItem>
                                                        <EuiFlexGroup alignItems="center">
                                                            <EuiFlexItem grow={false} >
                                                                <EuiAvatar type="space" size="l" name={receipt.company.name}></EuiAvatar>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem grow={false}>
                                                                <EuiTitle size="s">
                                                                    <h4>{receipt.company.name}</h4>
                                                                </EuiTitle>
                                                                <EuiText >{receipt.store ? receipt.store : "En ligne"}</EuiText>
                                                                <EuiText size="xs">{moment.utc(receipt.date).local().format("LTS")}</EuiText>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem className="align-items-end" grow>
                                                                <EuiText>{receipt.total.toFixed(2)} $</EuiText>
                                                            </EuiFlexItem>
                                                            <EuiFlexItem grow={false}>
                                                                <Link to={`/wallet/receipts/${receipt.id}`}> <span> {this.props.t("see")} <FiChevronRight className="align-middle"> </FiChevronRight></span></Link>
                                                            </EuiFlexItem>
                                                        </EuiFlexGroup>
                                                    </EuiFlexItem>
                                                </EuiPanel>
                                            )
                                        }
                                    </Fragment>
                                )
                            })
                            }
                        </EuiFlexGroup>
                        <EuiFlexGroup justifyContent="spaceAround">
                            <EuiFlexItem grow={false}>
                                <EuiPagination
                                    activePage={this.props.receipts.activePage}
                                    onPageClick={(page) => {
                                        this.props.updatePage(page)
                                        this.props.push(`?page=${page + 1}`);
                                        this.props.getReceipts({});
                                    }}

                                    pageCount={pagination?.last}>
                                </EuiPagination>
                            </EuiFlexItem>
                        </EuiFlexGroup>
                    </Fragment>
                )
            } else {
                return (
                    <EuiPageContent verticalPosition="center" horizontalPosition="center">
                        <EuiFlexItem>
                            <EuiEmptyPrompt iconType={() => <MdHourglassEmpty size="50"></MdHourglassEmpty>}
                                title={<EuiTitle size="s"><h2>{this.props.t("no-receipt.title")}</h2></EuiTitle>}
                                body={this.props.t("no-receipt.body")}
                                actions={<EuiButton onClick={() => this.props.history.push("/explore/companies")}>{this.props.t("no-receipt.action")}</EuiButton>}>
                            </EuiEmptyPrompt>
                        </EuiFlexItem>
                    </EuiPageContent>
                );
            }
        }
    }

}

const mapStateToProps = (state: SmartbillsRootState): ReceiptsStateProps => {
    const { wallet, } = state;
    return wallet.receipts
};
const mapDispatchToProps = (dispatch: any) => {
    return bindActionCreators({
        push,
        ...receiptsSlice.actions,
        getReceipts
    }, dispatch);
};

type ReceiptsProps = ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & WithTranslation;

const ReceiptsPageConnected = withTranslation("receipts")(connect(mapStateToProps, mapDispatchToProps)(withRouter(ReceiptsPage)));
export { ReceiptsPageConnected as ReceiptsPage }