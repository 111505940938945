import axios, { AxiosInstance } from "axios";
import { store } from "../../redux/store";
import { UserManager } from "oidc-client";
import createAuthRefreshInterceptor from 'axios-auth-refresh';
import { OAuth2Service } from "./authentication/oauth2-service";
export class SmartbillsAPI {
    private static instance: AxiosInstance;
    public static getInstance(): AxiosInstance {
        if (!SmartbillsAPI.instance) {
            SmartbillsAPI.instance = axios.create({
                baseURL: process.env.REACT_APP_API_URL
            });
        }

        return SmartbillsAPI.instance;
    }

    public static setToken(token: string | undefined) {
        var instance = SmartbillsAPI.getInstance()
        instance.interceptors.request.use(
            config => {
                if (token) {
                    config.headers['Authorization'] = 'Bearer ' + token;
                }
                return config;
            });

        const refreshAuthLogic = (failedRequest: any) => OAuth2Service.instance.signinSilent().then(user => {
            failedRequest.response.config.headers['Authorization'] = 'Bearer ' + user.access_token;
            return Promise.resolve();
        });

        createAuthRefreshInterceptor(SmartbillsAPI.instance, refreshAuthLogic);
        SmartbillsAPI.instance = instance;
    }
}

