import React, { ReactNode } from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";

import { UserState } from "redux-oidc";
import { connect } from "react-redux";
import { LoadingPage } from "../../component/loading/loading-page";

class PrivateRoute extends React.Component<RouteProps & { oidc: UserState }> {
    public constructor(props: any) {
        super(props);
    }
    public render(): ReactNode {
        const { component, ...rest }: any = this.props;
        const routeComponent = (props: any) => (
            this.props.oidc.user !== null
                ? React.createElement(component, props)
                : <Redirect to={{ pathname: '/login' }} />
        );

        if (this.props.oidc.isLoadingUser) {
            return <LoadingPage description="Loading the application..."></LoadingPage>;
        }

        return <Route {...rest} render={routeComponent} />;
    }
}
const mapStateToProps = (state: any): { oidc: UserState } => {
    const { oidc } = state;
    return {
        oidc: oidc,
    };
};


const PrivateRouteConnected = connect(mapStateToProps)(PrivateRoute);
export { PrivateRouteConnected as PrivateRoute }
