
import { DashboardLayoutAction } from "./dashboard-layout.actions";
import { DashboardLayoutStateProps } from "./dashboard-layout.state";
import { DashboardLayoutActionTypes } from "./dashboard-layout.types";

const initialState: DashboardLayoutStateProps = { sideMenuOpen: false, topMenuOpen: false };
export function DashboardLayoutReducer(
    state: DashboardLayoutStateProps = initialState,
    action: DashboardLayoutAction
): DashboardLayoutStateProps {
    switch (action.type) {
        case DashboardLayoutActionTypes.TOGGLE_SIDE_MENU:
            return {
                ...state,
                sideMenuOpen: action.payload.sideMenuOpen
            };
        case DashboardLayoutActionTypes.TOGGLE_TOP_MENU:
            return {
                ...state,
                topMenuOpen: action.payload.topMenuOpen
            };
            
        default:
            return state;
    }
}